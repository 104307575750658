import {
    Flex,
    Image,
    Modal,
    ModalBody,
    ModalContent,
    ModalOverlay,
    Text,
    Button,
} from "@chakra-ui/react";
import { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";

const ConditionsComponent: FC = () => {
    const [isModal, setIsModal] = useState<boolean>(false);

    const accederSite = () => {
        window.localStorage.setItem("accept_conditions", "1");
        setIsModal(false);
    };

    useEffect(() => {
        if (window.localStorage.getItem("accept_conditions")) {
            setIsModal(false);
        } else {
            setIsModal(true);
        }
    }, []);
    return (
        <>
            <Modal
                closeOnOverlayClick={false}
                isOpen={isModal}
                onClose={() => {}}
                isCentered
                size={"sm"}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalBody>
                        <Flex
                            alignItems={"center"}
                            justifyContent={"center"}
                            height={"250px"}
                            direction={"column"}
                            my={6}
                        >
                            <Image
                                alt="IVTMOBILITY"
                                src="/images/logo.svg"
                                width={[102, 150]}
                                objectFit={"contain"}
                            />
                            <Text mt={8} textAlign={"center"}>
                                En accédant à la plateforme, vous acceptez les{" "}
                                <Link
                                    style={{ textDecoration: "underline" }}
                                    to={"/conditions-generales-d-utilisation"}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    conditions générales d'utilisation
                                </Link>{" "}
                                et la{" "}
                                <Link
                                    to={"/politique-de-confidentialite"}
                                    style={{ textDecoration: "underline" }}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    politique de confidentialité
                                </Link>
                            </Text>
                            <Button
                                colorScheme="red"
                                my={5}
                                onClick={() => accederSite()}
                            >
                                Accéder à la plateforme
                            </Button>
                        </Flex>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
};

export default ConditionsComponent;
