import { appRequest } from "./services";

export type TPage = {
    id: number;
    titre: string;
    contenu: string;
};

export type TLightbox = {
    contenu: string;
};

export const getPage = async (id: number): Promise<TPage> => {
    const currentRequest = await appRequest<{
        data: { id: number; attributes: TPage };
    }>({
        url: `pages/${id}?populate=*`,
    });

    return {
        ...currentRequest.data.data.attributes,
        id: currentRequest.data.data.id,
    };
};

export const getLightbox = async (): Promise<TLightbox> => {
    const currentRequest = await appRequest<{
        data: { attributes: TLightbox };
    }>({
        url: `map-lightbox`,
    });

    return {
        ...currentRequest.data.data.attributes,
    };
};
