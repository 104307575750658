import {
    Box,
    Flex,
    Modal,
    ModalBody,
    ModalContent,
    ModalOverlay,
    Spinner,
    Text,
} from "@chakra-ui/react";
import axios from "axios";
import {
    Dispatch,
    FC,
    PropsWithChildren,
    SetStateAction,
    createContext,
    useEffect,
    useState,
} from "react";
import { loadListCentre } from "../services/centre.service";
import { TCabinet, TRelationMedecin } from "../types/centre";

type TMobileViewMode = "map" | "list";

type TApp = {
    listeCentre: Array<TCabinet>;
    isDetailsOpen: boolean;
    isModalConfirmOpen: boolean;
    isMobile: boolean;
    mobileViewMode: TMobileViewMode;
    setIsDetailsOpen: Dispatch<SetStateAction<boolean>>;
    setIsMobile: Dispatch<SetStateAction<boolean>>;
    setIsModalConfirmOpen: Dispatch<SetStateAction<boolean>>;
    setMobileViewMode: Dispatch<SetStateAction<TMobileViewMode>>;
    currentCentre: TCabinet | null;
    setCurrentCentre: Dispatch<SetStateAction<TCabinet | null>>;
    currentRelation: TRelationMedecin | null;
    setCurrentRelation: Dispatch<SetStateAction<TRelationMedecin | null>>;
    setListeCentre: Dispatch<SetStateAction<Array<TCabinet>>>;
};
type TAppProvider = PropsWithChildren<{}>;

const defaultState: TApp = {
    listeCentre: [],
    isDetailsOpen: false,
    isModalConfirmOpen: false,
    isMobile: false,
    mobileViewMode: "map",
    setIsDetailsOpen: () => {},
    setIsMobile: () => {},
    setIsModalConfirmOpen: () => {},
    setMobileViewMode: () => {},
    currentCentre: null,
    setCurrentCentre: () => {},
    currentRelation: null,
    setCurrentRelation: () => {},
    setListeCentre: () => {},
};

export const AppContext = createContext<TApp>(defaultState);

export const AppProvider: FC<TAppProvider> = ({ children }) => {
    const [listeCentre, setListeCentre] = useState<Array<TCabinet>>([]);
    const [isDetailsOpen, setIsDetailsOpen] = useState<boolean>(false);
    const [isModalConfirmOpen, setIsModalConfirmOpen] =
        useState<boolean>(false);
    const [mobileViewMode, setMobileViewMode] =
        useState<TMobileViewMode>("map");
    const [isMobile, setIsMobile] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [currentCentre, setCurrentCentre] = useState<TCabinet|null>(defaultState.currentCentre);
    const [currentRelation, setCurrentRelation] = useState<TRelationMedecin|null>(defaultState.currentRelation);

    const chargeListCentre = async () => {
        setListeCentre(await loadListCentre());
    };

    const handleWindowSizeChange = () => {
        setIsMobile(window.innerWidth <= 479);
    };

    useEffect(() => {
        chargeListCentre();
        window.addEventListener("load", handleWindowSizeChange);
        window.addEventListener("resize", handleWindowSizeChange);

        // Add a request interceptor
        let numberOfAjaxCAllPending = 0;

        axios.interceptors.request.use(
            function (config) {
                numberOfAjaxCAllPending++;

                if (config.data?.loading !== false) {
                    setIsLoading(true);
                }

                return config;
            },
            function (error) {
                return Promise.reject(error);
            }
        );

        // Add a response interceptor
        axios.interceptors.response.use(
            function (response) {
                numberOfAjaxCAllPending--;

                if (numberOfAjaxCAllPending === 0) {
                    setIsLoading(false);
                }
                return response;
            },
            function (error) {
                numberOfAjaxCAllPending--;
                if (numberOfAjaxCAllPending === 0) {
                    setIsLoading(false);
                }
                return Promise.reject(error);
            }
        );

        return () => {
            window.removeEventListener("load", handleWindowSizeChange);
            window.removeEventListener("resize", handleWindowSizeChange);
        };
    }, []);

    return (
        <>
            <AppContext.Provider
                value={{
                    listeCentre,
                    isDetailsOpen,
                    isModalConfirmOpen,
                    mobileViewMode,
                    isMobile,
                    setIsMobile,
                    setIsDetailsOpen,
                    setIsModalConfirmOpen,
                    setMobileViewMode,
                    currentCentre,
                    setCurrentCentre,
                    currentRelation,
                    setCurrentRelation,
                    setListeCentre,
                }}
            >
                <Modal
                    closeOnOverlayClick={false}
                    isOpen={isLoading}
                    onClose={() => {}}
                    isCentered
                    size={"sm"}
                >
                    <ModalOverlay />
                    <ModalContent>
                        <ModalBody>
                            <Flex
                                alignItems={"center"}
                                justifyContent={"center"}
                                height={"250px"}
                                direction={"column"}
                            >
                                <Spinner size={"xl"} color="red" />
                                <Text mt={8}>Chargement</Text>
                            </Flex>
                        </ModalBody>
                    </ModalContent>
                </Modal>
                <Box>{children}</Box>
            </AppContext.Provider>
        </>
    );
};
