import { useMatomo } from "@datapunt/matomo-tracker-react";
import "leaflet/dist/leaflet.css";
import { useContext, useEffect } from "react";
import ConditionsComponent from "../components/ConditionsComponent";
import FicheCentreComponent from "../components/FicheCentreComponent";
import FooterComponent from "../components/FooterComponent";
import HeaderComponent from "../components/HeaderComponent";
import ListCentreComponent from "../components/ListCentreComponent";
import MapComponent from "../components/MapComponent";
import ModalPriseRdvComponent from "../components/ModalPriseRdvComponent";
import { AppContext } from "../providers/AppProvider";

const HomePage = () => {
    const { isMobile, mobileViewMode } = useContext(AppContext);
    const { trackPageView } = useMatomo();

    useEffect(() => {
        trackPageView({});
    }, []);

    return (
        <>
            <HeaderComponent />
            {isMobile ? (
                <>
                    {mobileViewMode === "map" && <MapComponent />}
                    {mobileViewMode === "list" && <ListCentreComponent />}
                </>
            ) : (
                <>
                    <MapComponent />
                    <ListCentreComponent />
                </>
            )}

            <FicheCentreComponent />
            <ModalPriseRdvComponent />
            <FooterComponent />
            <ConditionsComponent />
        </>
    );
};

export default HomePage;
