import {
    Avatar,
    Box,
    Button,
    Flex,
    Image,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalOverlay,
    Slide,
    Stack,
    Text,
} from "@chakra-ui/react";
import { Fragment, useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../providers/AppProvider";
import { listRelationByCabinet } from "../services/centre.service";
import { baseUrl } from "../services/services";
import { TRelationMedecin } from "../types/centre";
import { useMatomo } from "@datapunt/matomo-tracker-react";

const FicheCentreComponent = () => {
    const mountedRef = useRef(true);
    const wrapperRef = useRef<any>(null);
    const { trackPageView } = useMatomo();
    const {
        isMobile,
        isDetailsOpen,
        setIsDetailsOpen,
        currentCentre,
        setCurrentCentre,
        setIsModalConfirmOpen,
        setCurrentRelation,
    } = useContext(AppContext);
    const [isMobileExpanded, setIsMobileExpanded] = useState<boolean>(false);
    const [tRelations, setTRelations] = useState<Array<TRelationMedecin>>([]);
    const [modalActiveIndex, setModalActiveIndex] = useState<number>(-1);

    const prendreRdv = (currentRelation: TRelationMedecin) => {
        setCurrentRelation(currentRelation);
        setIsModalConfirmOpen(true);

        if (currentCentre) {
            trackPageView({
                documentTitle: `Prendre RDV à ${currentRelation.cabinet.nom} avec Dr. ${currentRelation.medecin.nom} ${currentRelation.medecin.prenom}`,
                href: `/${currentRelation.cabinet.nom}/${currentRelation.medecin.nom}`,
            });
        }
    };

    // useEffect(() => {
    //     const handleClickOutside = (event: any) => {
    //         if (
    //             wrapperRef.current &&
    //             !wrapperRef.current.contains(event.target) &&
    //             isDetailsOpen &&
    //             !isMobile
    //         ) {
    //             setIsDetailsOpen(false);
    //             setCurrentCentre(null);
    //         }
    //     };

    //     document.addEventListener("mousedown", handleClickOutside);
    //     return () => {
    //         document.removeEventListener("mousedown", handleClickOutside);
    //         setIsMobileExpanded(false);
    //     };
    // }, [wrapperRef, isDetailsOpen]);

    useEffect(() => {
        if (currentCentre?.id) {
            (async () => {
                setTRelations(await listRelationByCabinet(currentCentre.id));
            })();
        } else {
            setTRelations([]);
        }

        return () => {
            mountedRef.current = false;
        };
    }, [currentCentre?.id]);

    return (
        <Slide
            direction={isMobile ? "bottom" : "right"}
            style={{
                zIndex: isMobile ? 11 : 5,
                width: isMobile ? "100%" : "680px",
            }}
            in={isDetailsOpen}
            unmountOnExit={true}
        >
            {currentCentre && (
                <>
                    <Box
                        height={[
                            isMobileExpanded ? "100vh" : "80vh",
                            "calc(100vh - 80px)",
                        ]}
                        className="details-container"
                        transition={"all .25s ease-in-out"}
                        width={"100%"}
                        bg="#ffffff"
                        position="absolute"
                        top={["unset", "80px"]}
                        bottom={[0, "unset"]}
                        right="0"
                        p={4}
                        pb={20}
                        boxShadow="4px 0 33px #434343"
                        ref={wrapperRef}
                        overflowY={"scroll"}
                        sx={{
                            "::-webkit-scrollbar": {
                                display: "none",
                            },
                        }}
                        borderRadius={["10px 10px 0 0", 0]}
                    >
                        <Box
                            textAlign={"right"}
                            position={"sticky"}
                            top={0}
                            zIndex={2}
                        >
                            <Box
                                width="30px"
                                height="30px"
                                role="button"
                                aria-label="Fermer"
                                bgImage="/images/close.svg"
                                ml={"auto"}
                                onClick={() => {
                                    setIsDetailsOpen(false);
                                    setCurrentCentre(null);
                                }}
                                boxShadow={"0px 0px 8px #909090"}
                                borderRadius={"100%"}
                            ></Box>
                        </Box>
                        <Box mt={5} maxW={"480px"} mx={"auto"}>
                            <Box
                                as="h1"
                                fontSize={[20, 24]}
                                fontWeight={900}
                                alignItems={"center"}
                                justifyContent={"center"}
                                display={"flex"}
                                flexDir={"column"}
                                textAlign="center"
                                _after={{
                                    content: "''",
                                    width: 133,
                                    height: 19,
                                    bgImage: "/images/arc-icon.svg",
                                    mt: 3,
                                }}
                            >
                                {currentCentre.nom}
                            </Box>
                            <Flex
                                pos={"relative"}
                                direction={["column", "row"]}
                                borderRadius={"10px"}
                                minH={"220px"}
                                overflow={["unset", "hidden"]}
                                mb={5}
                                bgGradient="linear(to-l, #E4373A, #DF5000)"
                                paddingLeft={[0, "200px"]}
                                paddingTop={["20px", 0]}
                                marginTop={["87px", "35px"]}
                                border={"1px #efefef solid"}
                            >
                                <Box
                                    width={["80px", "200px"]}
                                    height={["80px", "100%"]}
                                    bg={"#ffffff"}
                                    pos={"absolute"}
                                    top={["-50px", 0]}
                                    left={0}
                                    right={[0, "unset"]}
                                    margin={["auto", "unset"]}
                                    borderRadius={["10px", 0]}
                                    bottom={["unset", 0]}
                                    overflow={"hidden"}
                                    display={"flex"}
                                    alignItems={"center"}
                                    justifyContent={"center"}
                                >
                                    {currentCentre.logo?.url ? (
                                        <Image
                                            width={"80%"}
                                            height={"80%"}
                                            objectFit={"contain"}
                                            src={`${baseUrl}${currentCentre.logo.url}`}
                                        />
                                    ) : (
                                        <Image
                                            src="/images/marker.svg"
                                            alt=""
                                            width={"80%"}
                                            height={"80%"}
                                            objectFit={"contain"}
                                        />
                                    )}
                                </Box>
                                <Flex
                                    gap={3}
                                    direction={"column"}
                                    p={6}
                                    color={"#FFFFFF"}
                                    fontWeight={700}
                                    fontSize={14}
                                >
                                    <Flex gap={5}>
                                        <Image
                                            src="/images/icon-pin.svg"
                                            alt=""
                                        />
                                        <Box>
                                            <p>{currentCentre.adresse}</p>
                                            {currentCentre.adresse_comp_1 && (
                                                <p>
                                                    {
                                                        currentCentre.adresse_comp_1
                                                    }
                                                </p>
                                            )}
                                            {currentCentre.adresse_comp_2 && (
                                                <p>
                                                    {
                                                        currentCentre.adresse_comp_2
                                                    }
                                                </p>
                                            )}
                                            {currentCentre.code_postal && (
                                                <p>
                                                    {currentCentre.code_postal}{" "}
                                                    {currentCentre.ville}
                                                </p>
                                            )}
                                        </Box>
                                    </Flex>
                                    {currentCentre.acces_handicapes ===
                                        "oui" && (
                                        <Flex gap={5}>
                                            <Image
                                                src="/images/icon-handicape.svg"
                                                alt=""
                                                w={"20px"}
                                                flexShrink={0}
                                            />
                                            <Box>Accès handicapés</Box>
                                        </Flex>
                                    )}

                                    {currentCentre.parking !== "non" && (
                                        <Flex gap={5}>
                                            <Image
                                                src="/images/icon-car.svg"
                                                alt=""
                                                w={"20px"}
                                                flexShrink={0}
                                            />
                                            <Box>
                                                {currentCentre.parking ===
                                                    "gratuit" &&
                                                    "Parking gratuit"}
                                                {currentCentre.parking ===
                                                    "payant" &&
                                                    "Parking payant"}
                                                {currentCentre.parking ===
                                                    "reserve_patients" &&
                                                    "Parking pour patients"}
                                            </Box>
                                        </Flex>
                                    )}

                                    {currentCentre.telephone && (
                                        <Button
                                            as={"a"}
                                            colorScheme="light"
                                            rightIcon={
                                                <Image src="/images/chevron-light.svg" />
                                            }
                                            mt={4}
                                            variant={"outline"}
                                            href={`tel:${currentCentre.telephone}`}
                                        >
                                            Appeler le centre
                                        </Button>
                                    )}
                                </Flex>
                            </Flex>

                            {currentCentre.images &&
                                currentCentre.images.length > 0 && (
                                    <Flex
                                        gap={3}
                                        mb={5}
                                        wrap={"wrap"}
                                    >
                                        {currentCentre.images.map(
                                            (item, key) => (
                                                <Fragment key={key}>
                                                    <Image
                                                        src={`${baseUrl}${item.url}`}
                                                        alt={item.filename}
                                                        boxSize={"110px"}
                                                        borderRadius={"10px"}
                                                        role="button"
                                                        aria-label={
                                                            item.filename
                                                        }
                                                        objectFit={"cover"}
                                                        onClick={() =>
                                                            setModalActiveIndex(
                                                                key
                                                            )
                                                        }
                                                    />
                                                    <Modal
                                                        isOpen={
                                                            modalActiveIndex ===
                                                            key
                                                        }
                                                        size={"xl"}
                                                        onClose={() =>
                                                            setModalActiveIndex(
                                                                -1
                                                            )
                                                        }
                                                    >
                                                        <ModalOverlay />
                                                        <ModalContent>
                                                            <ModalCloseButton />
                                                            <ModalBody p={10}>
                                                                <Image
                                                                    src={`${baseUrl}${item.url}`}
                                                                    alt={
                                                                        item.filename
                                                                    }
                                                                    w={"100%"}
                                                                />
                                                            </ModalBody>
                                                        </ModalContent>
                                                    </Modal>
                                                </Fragment>
                                            )
                                        )}
                                    </Flex>
                                )}

                            <Flex alignItems={"center"} gap={3} mt={5}>
                                <Image
                                    src="/images/icon-hours.svg"
                                    width={"45px"}
                                />
                                <Box
                                    color={"var(--color-red)"}
                                    textTransform={"uppercase"}
                                    fontWeight={900}
                                >
                                    Horaires d'ouverture
                                </Box>
                            </Flex>
                            <Flex
                                mt={3}
                                gap={4}
                                color="var(--chakra-colors-gray-600)"
                                fontSize={"14px"}
                            >
                                <Stack gap={3} flex={1}>
                                    <Box>
                                        <Text fontWeight={700}>Lundi</Text>
                                        <Flex gap={3}>
                                            {currentCentre.horaires_lundi
                                                .length === 0 && "Fermé"}
                                            {currentCentre.horaires_lundi.map(
                                                (item, key) => (
                                                    <Box key={key}>
                                                        {item.heure_debut}:
                                                        {item.minute_debut}
                                                        {" - "}
                                                        {item.heure_fin}:
                                                        {item.minute_fin}
                                                    </Box>
                                                )
                                            )}
                                        </Flex>
                                    </Box>
                                    <Box>
                                        <Text fontWeight={700}>Mardi</Text>
                                        <Flex gap={3}>
                                            {currentCentre.horaires_mardi
                                                .length === 0 && "Fermé"}
                                            {currentCentre.horaires_mardi.map(
                                                (item, key) => (
                                                    <Box key={key}>
                                                        {item.heure_debut}:
                                                        {item.minute_debut}
                                                        {" - "}
                                                        {item.heure_fin}:
                                                        {item.minute_fin}
                                                    </Box>
                                                )
                                            )}
                                        </Flex>
                                    </Box>
                                    <Box>
                                        <Text fontWeight={700}>Mercredi</Text>
                                        <Flex gap={3}>
                                            {currentCentre.horaires_mercredi
                                                .length === 0 && "Fermé"}
                                            {currentCentre.horaires_mercredi.map(
                                                (item, key) => (
                                                    <Box key={key}>
                                                        {item.heure_debut}:
                                                        {item.minute_debut}
                                                        {" - "}
                                                        {item.heure_fin}:
                                                        {item.minute_fin}
                                                    </Box>
                                                )
                                            )}
                                        </Flex>
                                    </Box>
                                </Stack>
                                <Stack gap={3} flex={1}>
                                    <Box>
                                        <Text fontWeight={700}>Jeudi</Text>
                                        <Flex gap={3}>
                                            {currentCentre.horaires_jeudi
                                                .length === 0 && "Fermé"}
                                            {currentCentre.horaires_jeudi.map(
                                                (item, key) => (
                                                    <Box key={key}>
                                                        {item.heure_debut}:
                                                        {item.minute_debut}
                                                        {" - "}
                                                        {item.heure_fin}:
                                                        {item.minute_fin}
                                                    </Box>
                                                )
                                            )}
                                        </Flex>
                                    </Box>
                                    <Box>
                                        <Text fontWeight={700}>Vendredi</Text>
                                        <Flex gap={3}>
                                            {currentCentre.horaires_vendredi
                                                .length === 0 && "Fermé"}
                                            {currentCentre.horaires_vendredi.map(
                                                (item, key) => (
                                                    <Box key={key}>
                                                        {item.heure_debut}:
                                                        {item.minute_debut}
                                                        {" - "}
                                                        {item.heure_fin}:
                                                        {item.minute_fin}
                                                    </Box>
                                                )
                                            )}
                                        </Flex>
                                    </Box>
                                    <Box>
                                        <Text fontWeight={700}>Samedi</Text>
                                        <Flex gap={3}>
                                            {currentCentre.horaires_samedi
                                                .length === 0 && "Fermé"}
                                            {currentCentre.horaires_samedi.map(
                                                (item, key) => (
                                                    <Box key={key}>
                                                        {item.heure_debut}:
                                                        {item.minute_debut}
                                                        {" - "}
                                                        {item.heure_fin}:
                                                        {item.minute_fin}
                                                    </Box>
                                                )
                                            )}
                                        </Flex>
                                    </Box>
                                    <Box>
                                        <Text fontWeight={700}>Dimanche</Text>
                                        <Flex gap={3}>
                                            {currentCentre.horaires_dimanche
                                                .length === 0 && "Fermé"}
                                            {currentCentre.horaires_dimanche.map(
                                                (item, key) => (
                                                    <Box key={key}>
                                                        {item.heure_debut}:
                                                        {item.minute_debut}
                                                        {" - "}
                                                        {item.heure_fin}:
                                                        {item.minute_fin}
                                                    </Box>
                                                )
                                            )}
                                        </Flex>
                                    </Box>
                                </Stack>
                            </Flex>
                            {tRelations.length > 0 && (
                                <Flex alignItems={"center"} gap={3} mt={6}>
                                    <Image src="/images/icon-medecin.svg" />
                                    <Box
                                        color={"var(--color-red)"}
                                        textTransform={"uppercase"}
                                        fontWeight={900}
                                    >
                                        Médecins
                                    </Box>
                                </Flex>
                            )}
                            {tRelations.map((item, key) => (
                                <Flex key={key} direction={"column"} gap={7}>
                                    <Flex
                                        direction={"column"}
                                        mt={10}
                                        alignItems={"flex-start"}
                                    >
                                        <Flex gap={5} alignItems={"center"}>
                                            <Box
                                                borderRadius={"5px"}
                                                width={"100px"}
                                                height={"100px"}
                                            >
                                                <Avatar
                                                    size={"xl"}
                                                    fontWeight={700}
                                                    name={`${item.medecin.nom} ${item.medecin.prenom}`}
                                                    bg={"#efefef"}
                                                    color={"#444444"}
                                                    src={
                                                        baseUrl +
                                                        item.medecin.photo
                                                    }
                                                />
                                            </Box>
                                            <Box>
                                                <Box
                                                    fontWeight={900}
                                                    fontSize={"16px"}
                                                >
                                                    Dr{" "}
                                                    <Text
                                                        as={"span"}
                                                        textTransform={
                                                            "uppercase"
                                                        }
                                                    >
                                                        {item.medecin.nom}
                                                    </Text>{" "}
                                                    {item.medecin.prenom}
                                                </Box>
                                                <Box fontSize={"14px"}>
                                                    {item.medecin.profession}
                                                </Box>
                                            </Box>
                                        </Flex>
                                        <Box
                                            color="var(--color-red)"
                                            fontWeight={900}
                                            mt={5}
                                        >
                                            Créneaux d'injections
                                        </Box>
                                        <Box mt={3}>
                                            {item.horaires_injection}
                                        </Box>
                                        <Button
                                            rightIcon={
                                                <Image src="/images/chevron-light.svg" />
                                            }
                                            mt={5}
                                            colorScheme="light"
                                            variant="outline"
                                            bgGradient="linear(to-l, #E4373A, #DF5000)"
                                            color={"#FFFFFF"}
                                            type="button"
                                            onClick={() => prendreRdv(item)}
                                        >
                                            Prendre RDV
                                        </Button>
                                    </Flex>
                                </Flex>
                            ))}
                        </Box>
                    </Box>
                    <Box
                        position={"absolute"}
                        left={0}
                        bottom={0}
                        right={0}
                        height={["50px", "42px"]}
                        zIndex={2}
                        bgGradient={[
                            "linear(to-t, #ffffff, #ffffff, transparent)",
                        ]}
                    >
                        {isMobile && !isMobileExpanded && (
                            <Box
                                role="button"
                                textAlign={"center"}
                                onClick={() => setIsMobileExpanded(true)}
                            >
                                <Image
                                    src="/images/chevron-round-dark.svg"
                                    alt=""
                                    margin={"auto"}
                                />
                            </Box>
                        )}
                    </Box>
                </>
            )}
        </Slide>
    );
};

export default FicheCentreComponent;
