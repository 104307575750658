import {
    Box,
    Button,
    Checkbox,
    Image,
    List,
    ListItem,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
} from "@chakra-ui/react";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { AppContext } from "../providers/AppProvider";
import { useMatomo } from "@datapunt/matomo-tracker-react";

const ModalPriseRdvComponent = () => {
    const mountedRef = useRef(true);
    const { isModalConfirmOpen, setIsModalConfirmOpen, currentRelation } =
        useContext(AppContext);
    const [isAccepted, setIsAccepted] = useState<boolean>(false);
    const [isModalAlaxione, setIsModalAlaxione] = useState<boolean>(false);
    const { trackPageView } = useMatomo();

    const listPieces = useMemo(() => {
        const tRes: Array<string> = [];

        if (currentRelation?.piece_courrier_medecin) {
            tRes.push("Courrier du médecin");
        }

        if (currentRelation?.piece_compte_rendu) {
            tRes.push("Compte-rendu de la dernière consultation");
        }

        if (currentRelation?.piece_last_oct) {
            tRes.push("Dernier OCT");
        }

        if (currentRelation?.piece_poss_prod_injec) {
            tRes.push("Possession du produit à injecter");
        }

        return tRes;
    }, [currentRelation?.id]);

    const confirmRdv = () => {
        setIsModalConfirmOpen(false);
        let url = "";

        if (currentRelation) {
            trackPageView({
                documentTitle: `Prendre RDV à ${currentRelation.cabinet.nom} avec Dr. ${currentRelation.medecin.nom} ${currentRelation.medecin.prenom} sur ${currentRelation.solution_resa}`,
                href: `/${currentRelation.cabinet.nom}/${currentRelation.medecin.nom}/${currentRelation.solution_resa}`,
            });
        }

        if (currentRelation?.solution_resa) {
            switch (currentRelation.solution_resa) {
                case "alaxione":
                    setIsModalAlaxione(true);
                    return;
                case "doctolib":
                    url = currentRelation.solution_resa_doctolib;
                    break;
                case "custom":
                    url = currentRelation.solution_resa_custom;
                    break;
                case "phone":
                    url = "tel:" + currentRelation.solution_resa_tel;
                    break;
            }
        }

        setIsAccepted(false);
        window.open(url, "_blank")?.focus();
    };

    useEffect(() => {
        setIsAccepted(false);

        return () => {
            setIsModalAlaxione(false);
            mountedRef.current = false;
        };
    }, []);

    return (
        <>
            {currentRelation && (
                <Modal
                    isOpen={isModalAlaxione}
                    onClose={() => setIsModalAlaxione(false)}
                    closeOnOverlayClick={false}
                >
                    <ModalOverlay />
                    <ModalContent
                        width={"980px"}
                        maxW={"unset"}
                        height={"583px"}
                    >
                        <ModalCloseButton>
                            <Image src="/images/close.svg" alt="" />
                        </ModalCloseButton>
                        <ModalBody>
                            <iframe
                                title="Reservation Alaxione"
                                width="100%"
                                height="100%"
                                frameBorder="none"
                                src={`${currentRelation.solution_resa_alaxione}&noBG=true&noClose=true`}
                            ></iframe>
                        </ModalBody>
                    </ModalContent>
                </Modal>
            )}

            {currentRelation && (
                <Modal
                    closeOnOverlayClick={false}
                    isOpen={isModalConfirmOpen}
                    onClose={() => {
                        setIsModalConfirmOpen(false);
                        setIsAccepted(false);
                    }}
                >
                    <ModalOverlay />
                    <ModalContent
                        width={["90%", "647px"]}
                        maxW={"unset"}
                        p={[0, 6]}
                    >
                        <ModalHeader
                            textAlign={"center"}
                            fontWeight={900}
                            alignItems={"center"}
                            justifyContent={"center"}
                            display={"flex"}
                            flexDir={"column"}
                            _after={{
                                content: "''",
                                width: 133,
                                height: 19,
                                bgImage: "/images/arc-icon.svg",
                                mt: 3,
                            }}
                        >
                            <Box
                                fontSize={["20px", "24px"]}
                                lineHeight={["28px", "45px"]}
                            >
                                Vous êtes sur le point de prendre rendez-vous
                            </Box>
                            <Box color="var(--color-red)" fontSize={"18px"}>
                                avec le Dr {currentRelation.medecin.nom}{" "}
                                {currentRelation.medecin.prenom}
                            </Box>
                        </ModalHeader>
                        <ModalCloseButton>
                            <Image src="/images/close.svg" alt="" />
                        </ModalCloseButton>
                        <ModalBody pb={6}>
                            <Box
                                color="var(--color-red)"
                                fontSize={"18px"}
                                fontWeight={800}
                            >
                                Pour prendre rendez-vous
                            </Box>
                            <Box fontWeight={700} fontSize={14}>
                                Quelles sont les pièces indispensables à fournir
                                lors de votre visite pour l'injection ?
                            </Box>
                            <List fontSize={14} mt={3}>
                                {listPieces.map((item, key) => (
                                    <ListItem
                                        display={"flex"}
                                        alignItems={"center"}
                                        gap={3}
                                        key={key}
                                    >
                                        <Box
                                            width={"7px"}
                                            height={"7px"}
                                            bg="var(--color-red)"
                                            borderRadius={"100%"}
                                        />
                                        {item}
                                    </ListItem>
                                ))}
                            </List>
                            <Box mt={5}>
                                <Checkbox
                                    size={"lg"}
                                    borderColor={"var(--color-red)"}
                                    colorScheme="red"
                                    onChange={(e) => {
                                        setIsAccepted(e.target.checked);
                                    }}
                                >
                                    <Box
                                        as="span"
                                        fontSize={"14px"}
                                        fontWeight={700}
                                    >
                                        Je confirme avoir ces pièces
                                    </Box>
                                </Checkbox>
                            </Box>
                            <Box textAlign={"center"} mt={5}>
                                <Button
                                    rightIcon={
                                        <Image src="/images/icon-external.svg" />
                                    }
                                    mt={5}
                                    colorScheme="light"
                                    variant="outline"
                                    bgGradient="linear(to-l, #E4373A, #DF5000)"
                                    color={"#FFFFFF"}
                                    type="button"
                                    isDisabled={!isAccepted}
                                    onClick={() => confirmRdv()}
                                >
                                    {currentRelation.solution_resa === "phone"
                                        ? "Tel: " + currentRelation.solution_resa_tel
                                        : "Je souhaite prendre RDV"}
                                </Button>
                            </Box>
                        </ModalBody>
                    </ModalContent>
                </Modal>
            )}
        </>
    );
};

export default ModalPriseRdvComponent;
