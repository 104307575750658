import { Box, Button, Flex, Image, Text } from "@chakra-ui/react";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import { useContext, useRef, useState } from "react";
import { AppContext } from "../providers/AppProvider";
import { baseUrl } from "../services/services";
import { TCabinet } from "../types/centre";

const ListCentreComponent = () => {
    const refScroller = useRef<any>(null);
    const { trackPageView } = useMatomo();
    const { listeCentre, setIsDetailsOpen, setCurrentCentre, isMobile } =
        useContext(AppContext);
    const [listScrollPosition, setListScrollPosition] = useState<number>(0);

    const scrollAction = (direction: "more" | "less") => {
        const currentElm = refScroller.current;
        if (!currentElm) {
            return;
        }

        const step = 50;
        let travelDistance = 0;
        switch (direction) {
            case "more":
                if (currentElm.scrollHeight > currentElm.clientHeight) {
                    travelDistance = currentElm.scrollTop + step;
                    if (travelDistance > currentElm.clientHeight) {
                        travelDistance = currentElm.clientHeight;
                    }
                }
                break;
            case "less":
                travelDistance = currentElm.scrollTop - step;

                if (travelDistance < 0) {
                    travelDistance = 0;
                }
                break;
        }

        setListScrollPosition(travelDistance);
        currentElm.scrollTo({
            top: travelDistance,
            behavior: "smooth",
        });
    };

    const loadCentreDetails = (centre: TCabinet) => {
        setIsDetailsOpen(true);
        setCurrentCentre(centre);

        // Track action click details
        trackPageView({
            documentTitle: centre.nom,
            href: `/${centre.nom}`,
        });
    };

    return (
        <Box
            position="fixed"
            top={["80px", "130px"]}
            right="0"
            zIndex={3}
            width={["100%", "260px"]}
            bottom={["95px", "unset"]}
            bg="#FFFFFF"
            borderRadius={[0, "10px 0 0 10px"]}
        >
            <Box padding="10px" bg="#F8F1EF">
                <Box fontSize="20px" fontWeight={900} textAlign="center">
                    Liste des cabinets
                </Box>
                <Box
                    textAlign="right"
                    color="var(--color-red)"
                    fontWeight={700}
                    fontSize="12px"
                    mt={3}
                >
                    {listeCentre.length} cabinet{listeCentre.length > 1 && "s"}
                </Box>
            </Box>
            <Button
                position="absolute"
                top={"50px"}
                left={0}
                right={0}
                margin={"auto"}
                borderRadius={"100px 100px 0 0"}
                bg="#ffffff"
                onClick={() => scrollAction("less")}
                width={"57px"}
                height={"30px"}
                alignItems={"center"}
                justifyContent={"center"}
                padding={0}
                isDisabled={listScrollPosition <= 0}
                display={["none", "flex"]}
            >
                <Image src="/images/arrow-red-bold.svg" />
            </Button>
            <Box
                height="60vh"
                overflowY={"scroll"}
                ref={refScroller}
                pb={["60px", 0]}
            >
                <Box
                    p={2}
                    display="flex"
                    flexDir="column"
                    gap={2}
                    className="element"
                >
                    {listeCentre
                        .sort((a, b) => {
                            if (isMobile) {
                                if (a.nom < b.nom) {
                                    return -1;
                                }
                                if (a.nom < b.nom) {
                                    return 1;
                                }
                            }

                            return 0;
                        })
                        .map((item, key) => (
                            <Box
                                key={key}
                                border="1px #F8F1EF solid"
                                borderRadius="5px"
                                display="flex"
                                height="66px"
                                overflow="hidden"
                                role="button"
                                onClick={() => loadCentreDetails(item)}
                                bg={"#FFFFFF"}
                                transition={"all .25s ease-in-out"}
                                _hover={{
                                    boxShadow: "3px 3px 3px #EFEFEF",
                                }}
                            >
                                <Box width="50px" flexShrink="0" mr={2}>
                                    {item.logo?.url ? (
                                        <Image
                                            src={`${baseUrl}${item.logo.url}`}
                                            alt=""
                                            width={"100%"}
                                            height={"100%"}
                                            objectFit={"contain"}
                                        />
                                    ) : (
                                        <Image
                                            src="/images/marker.svg"
                                            alt=""
                                            width={"100%"}
                                            height={"100%"}
                                            objectFit={"contain"}
                                        />
                                    )}
                                </Box>
                                <Text
                                    noOfLines={2}
                                    fontSize="12px"
                                    fontWeight={700}
                                    mt={1}
                                >
                                    {item.nom}
                                </Text>
                                <Flex
                                    flexShrink={0}
                                    ml={"auto"}
                                    alignItems={"center"}
                                    pl={4}
                                    mr={2}
                                >
                                    <Image
                                        src="/images/arrow-red.svg"
                                        alt=""
                                        width="5px"
                                    />
                                </Flex>
                            </Box>
                        ))}
                </Box>
            </Box>
            <Flex
                role="button"
                position="absolute"
                bottom={"-30px"}
                left={0}
                right={0}
                margin={"auto"}
                borderRadius={"0 0 100px 100px"}
                bg="#ffffff"
                onClick={() => scrollAction("more")}
                width={"57px"}
                height={"30px"}
                alignItems={"center"}
                justifyContent={"center"}
                display={["none", "flex"]}
            >
                <Image
                    src="/images/arrow-red-bold.svg"
                    transform={"rotate(-180deg)"}
                />
            </Flex>
        </Box>
    );
};

export default ListCentreComponent;
