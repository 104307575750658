import { TCabinet, TRelationMedecin } from "../types/centre";
import { appRequest } from "./services";

const getPhotoUrl = (item: any) => {
    let photo = "";

    if (item?.attributes.formats?.medium?.url) {
        photo = item.attributes.formats.medium.url;
    } else {
        photo = item?.attributes.url;
    }

    return photo;
};

const getReturnedData = (dataRes: any) => {
    return {
        id: dataRes.id,
        horaires_injection: dataRes.attributes.horaires_injection,
        medecin: {
            id: dataRes.attributes.medecin.data.id,
            uid: dataRes.attributes.medecin.data.attributes.uid,
            nom: dataRes.attributes.medecin.data.attributes.nom,
            prenom: dataRes.attributes.medecin.data.attributes.prenom,
            photo: getPhotoUrl(
                dataRes.attributes.medecin.data.attributes.photo.data
            ),
            profession: dataRes.attributes.medecin.data.attributes.profession,
        },
        cabinet: {
            id: dataRes.attributes.cabinet.data.id,
            uid: dataRes.attributes.cabinet.data.attributes.uid,
            nom: dataRes.attributes.cabinet.data.attributes.nom,
        },
        piece_compte_rendu: dataRes.attributes.piece_compte_rendu,
        piece_courrier_medecin: dataRes.attributes.piece_courrier_medecin,
        piece_last_oct: dataRes.attributes.piece_last_oct,
        piece_poss_prod_injec: dataRes.attributes.piece_poss_prod_injec,
        solution_resa: dataRes.attributes.solution_resa,
        solution_resa_alaxione: dataRes.attributes.solution_resa_alaxione,
        solution_resa_custom: dataRes.attributes.solution_resa_custom,
        solution_resa_doctolib: dataRes.attributes.solution_resa_doctolib,
        solution_resa_tel: dataRes.attributes.solution_resa_tel,
    } as TRelationMedecin;
};

export const loadListCentre = async (
    idDept?: number
): Promise<Array<TCabinet>> => {
    let url = `cabinets?populate=*&filters[status][$eq]=published&pagination[pageSize]=999`;

    if (idDept) {
        url += `&filters[departement][id]=${idDept}`;
    }

    const currentRequest = await appRequest<{
        data: Array<{ id: number; attributes: any }>;
    }>({
        url,
    });

    return currentRequest.data.data.map((item) => ({
        ...item.attributes,
        id: item.id,
        departement: item.attributes.departement.data?.attributes.code,
        logo: {
            id: item.attributes.logo.data?.id,
            url: getPhotoUrl(item.attributes.logo.data),
            filename: item.attributes.logo.data?.attributes.name,
        },
        images: item.attributes.images?.data?.map((elm: any) => {
            return {
                id: elm.id,
                filename: elm.attributes.name,
                url: getPhotoUrl(elm),
            };
        }),
    }));
};

export const listRelationByCabinet = async (id_cabinet: number) => {
    const currentRequest = await appRequest<{
        data: Array<{ id: number; attributes: any }>;
    }>({
        url: `relation-medecin-cabinets?populate[0]=*&populate[1]=medecin.photo&populate[2]=cabinet.logo&filters[cabinet][status][$eq]=published&filters[cabinet][id][$eq]=${id_cabinet}`,
    });

    return currentRequest.data.data.map((item) => {
        return getReturnedData(item);
    }) as Array<TRelationMedecin>;
};
