export const array_chunk = <T>(
    inputArray: Array<T>,
    size: number
): Array<Array<T>> => {
    return inputArray.reduce((resultArray: Array<Array<T>>, item, index) => {
        const chunkIndex = Math.floor(index / size);

        if (!resultArray[chunkIndex]) {
            resultArray[chunkIndex] = [];
        }

        resultArray[chunkIndex].push(item);

        return resultArray;
    }, []);
};
