import { appRequest } from "./services";

export interface IDeptList {
    id: number;
    code: string;
    nom: string;
}

export const getDeptService = async (): Promise<Array<IDeptList>> => {
    const currentRequest = await appRequest<{
        data: Array<{ id: number; attributes: IDeptList }>;
    }>({
        url: `departements?populate=*&pagination[pageSize]=999`,
    });

    return currentRequest.data.data.map((item) => ({
        ...item.attributes,
        id: item.id,
    }));
};
