import {
    Box,
    Button,
    IconButton,
    Image,
    SlideFade,
    Stack,
} from "@chakra-ui/react";
import {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { array_chunk } from "../helpers";
import { AppContext } from "../providers/AppProvider";
import { loadListCentre } from "../services/centre.service";
import { IDeptList, getDeptService } from "../services/dept.service";
import { TCabinet } from "../types/centre";
import { CloseIcon } from "@chakra-ui/icons";

const HeaderComponent = () => {
    const { mobileViewMode, setMobileViewMode } = useContext(AppContext);
    const refMounted = useRef(true);
    const navigate = useNavigate();
    const wrapperRef = useRef<any>(null);
    const [params] = useSearchParams();
    const defaultBgSearch = "#878787";
    const defaultColorSearch = "#FFFFFF";
    const defaultArrowSearch = "arrow-light.svg";

    const [bgSearch, setBgSearch] = useState<string>(defaultBgSearch);
    const [colorSearch, setColorSearch] = useState<string>(defaultColorSearch);
    const [arrowSearch, setArrowSearch] = useState<string>(defaultArrowSearch);
    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
    const [listeCentre, setListeCentre] = useState<Array<TCabinet>>([]);
    const [tDept, setTDept] = useState<Array<IDeptList>>([]);

    const hideDetailsMenu = () => {
        setBgSearch(defaultBgSearch);
        setColorSearch(defaultColorSearch);
        setArrowSearch(defaultArrowSearch);
    };

    const countByDept = useCallback(
        (codeDept: string): number => {
            if (listeCentre.length > 0) {
                return listeCentre.filter(
                    (item) => item.departement === codeDept
                ).length;
            }

            return 0;
        },
        [listeCentre.length]
    );

    const getDeptNameById = useMemo(() => {
        const dept = params.get("dept");

        if (dept) {
            const tFilter = tDept.filter((item) => item.id === parseInt(dept));
            if (tFilter.length === 1) {
                return tFilter[0].nom;
            }
        }

        return "Sélectionner votre département de prise en charge...";
    }, [params.get("dept")]);

    useEffect(() => {
        (async () => {
            setTDept(await getDeptService());
            setListeCentre(await loadListCentre());
        })();

        return () => {
            refMounted.current = false;
        };
    }, []);

    useEffect(() => {
        if (isMenuOpen) {
            setBgSearch("#FFFFFF");
            setColorSearch("#444444");
            setArrowSearch("arrow-dark.svg");
        } else {
            hideDetailsMenu();
        }
    }, [isMenuOpen]);

    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (
                wrapperRef.current &&
                !wrapperRef.current.contains(event.target) &&
                isMenuOpen
            ) {
                setIsMenuOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [wrapperRef, isMenuOpen]);

    return (
        <Box
            display="flex"
            height="80px"
            flexDirection="row"
            position="relative"
            ref={wrapperRef}
            zIndex={10}
            bg="transparent"
        >
            <Box
                height={[70, 116]}
                width={[140, 235]}
                display="flex"
                alignItems="center"
                justifyContent="center"
                backgroundColor="#FFFFFF"
                position="absolute"
                top={0}
                left={0}
                borderBottomRightRadius="10px"
            >
                <Box as="a" href="/">
                    <Image
                        alt="IVTMOBILITY"
                        src="/images/logo.svg"
                        width={[102, 150]}
                    />
                </Box>
            </Box>

            {/* Box switch mobile only */}
            <Box
                ml={"auto"}
                display={["flex", "none"]}
                alignItems={"center"}
                justifyContent={"center"}
                bg={"#000000"}
                h={50}
                w={140}
                borderRadius={200}
                mt={3}
                mr={3}
                gap={2}
            >
                <Button
                    role="button"
                    bg={
                        mobileViewMode === "map"
                            ? "var(--color-red) !important"
                            : "transparent"
                    }
                    color={"#FFFFFF"}
                    px={3}
                    onClick={() => setMobileViewMode("map")}
                >
                    Carte
                </Button>
                <Button
                    role="button"
                    bg={
                        mobileViewMode === "list"
                            ? "var(--color-red) !important"
                            : "transparent"
                    }
                    color={"#FFFFFF"}
                    px={3}
                    onClick={() => setMobileViewMode("list")}
                >
                    Liste
                </Button>
            </Box>

            {/** Search on desktop */}
            <Box
                backgroundColor="#444444"
                display={["none", "flex"]}
                alignItems={"center"}
                justifyContent={"center"}
                marginLeft="235px"
                padding="20px"
                flex="1"
                position="relative"
                gap={4}
            >
                <Box
                    height="44px"
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    backgroundColor={bgSearch}
                    borderRadius="5px"
                    padding="0 15px"
                    role="button"
                    flex={1}
                    aria-label="Sélectionner votre département de prise en charge..."
                    onClick={() => setIsMenuOpen((prev) => !prev)}
                >
                    <Image alt="" src="/images/pin-dark.svg" width="16px" />
                    <Box ml={3} color={colorSearch} fontWeight={700}>
                        {getDeptNameById}
                    </Box>
                    <Image
                        ml="auto"
                        alt=""
                        src={`/images/${arrowSearch}`}
                        width="10px"
                    />
                </Box>
                {params.get("dept") && (
                    <IconButton
                        aria-label="Annuler"
                        onClick={() => navigate("/")}
                    >
                        <CloseIcon />
                    </IconButton>
                )}
                <SlideFade
                    in={isMenuOpen}
                    offsetX={0}
                    offsetY={0}
                    reverse={true}
                    unmountOnExit={true}
                >
                    <Box
                        position="absolute"
                        top="70px"
                        left="20px"
                        right="20px"
                        backgroundColor="#FFFFFF"
                        borderRadius="5px"
                        boxShadow="3px 10px 15px #a0a0a0"
                        padding="10px 20px 20px"
                    >
                        <Box
                            fontWeight="700"
                            borderBottom="1px #F8F1EF solid"
                            pb={3}
                        >
                            {getDeptNameById}
                        </Box>
                        <Box
                            display="grid"
                            gridTemplateColumns="repeat(4, 1fr)"
                            gap="20px 30px"
                            mt={3}
                        >
                            {array_chunk<IDeptList>(tDept, 26).map(
                                (tItem, key) => (
                                    <Stack key={key} gap={3}>
                                        {tItem.map((item, subkey) => (
                                            <Box
                                                key={`${key}-${subkey}`}
                                                display="flex"
                                                alignItems="center"
                                                fontWeight={700}
                                                color={
                                                    countByDept(item.code) > 0
                                                        ? "#444444"
                                                        : "#C7C7C7"
                                                }
                                                lineHeight="18px"
                                                cursor={
                                                    countByDept(item.code) > 0
                                                        ? "pointer"
                                                        : "default"
                                                }
                                                pointerEvents={
                                                    countByDept(item.code) > 0
                                                        ? "all"
                                                        : "none"
                                                }
                                                role="group"
                                                position="relative"
                                                _before={{
                                                    content: "''",
                                                    position: "absolute",
                                                    top: "-10px",
                                                    left: "-10px",
                                                    right: "-10px",
                                                    bottom: "-10px",
                                                    borderRadius: "5px",
                                                    zIndex: 0,
                                                    transition:
                                                        "all .2s ease-in-out",
                                                    bgGradient:
                                                        "linear(to-l, #E4373A, #DF5000)",
                                                    transform: "scaleY(0)",
                                                }}
                                                _hover={{
                                                    _before: {
                                                        transform: "scaleY(1)",
                                                    },
                                                }}
                                                onClick={() => {
                                                    setIsMenuOpen(false);
                                                    navigate(
                                                        `/?dept=${item.id}`
                                                    );
                                                }}
                                            >
                                                <Box
                                                    whiteSpace="nowrap"
                                                    position="relative"
                                                    transition="all .25s ease-in-out"
                                                    _groupHover={{
                                                        color: "#FFFFFF",
                                                    }}
                                                >
                                                    {item.code} -
                                                </Box>
                                                <Box
                                                    ml={1}
                                                    position="relative"
                                                    transition="all .25s ease-in-out"
                                                    _groupHover={{
                                                        color: "#FFFFFF",
                                                    }}
                                                >
                                                    {item.nom}
                                                </Box>
                                                {countByDept(item.code) > 0 && (
                                                    <Box
                                                        ml={4}
                                                        width="20px"
                                                        height="20px"
                                                        bg="var(--color-red)"
                                                        borderRadius="100%"
                                                        whiteSpace="nowrap"
                                                        fontFamily="var(--font-secondary)"
                                                        fontSize="16px"
                                                        color="#FFFFFF"
                                                        display="flex"
                                                        alignItems="center"
                                                        justifyContent="center"
                                                        paddingTop="3px"
                                                        position="relative"
                                                        transition="all .25s ease-in-out"
                                                        _groupHover={{
                                                            color: "var(--color-red)",
                                                            backgroundColor:
                                                                "#FFFFFF",
                                                        }}
                                                    >
                                                        {countByDept(item.code)}
                                                    </Box>
                                                )}
                                            </Box>
                                        ))}
                                    </Stack>
                                )
                            )}
                        </Box>
                    </Box>
                </SlideFade>
            </Box>
        </Box>
    );
};

export default HeaderComponent;
