import { CloseIcon } from "@chakra-ui/icons";
import { Box, Flex, IconButton, Image, Text } from "@chakra-ui/react";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import { Fragment, useContext, useEffect, useRef, useState } from "react";
import { MapContainer, Marker, ZoomControl } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";
import VectorTileLayer from "react-leaflet-vector-tile-layer";
import { useSearchParams } from "react-router-dom";
import { AppContext } from "../providers/AppProvider";
import { loadListCentre } from "../services/centre.service";
import { getLightbox } from "../services/page.service";
import { TCabinet } from "../types/centre";

const MapComponent = () => {
    const refMounted = useRef(true);
    const {
        isMobile,
        listeCentre,
        setIsDetailsOpen,
        setCurrentCentre,
        setListeCentre,
        currentCentre,
    } = useContext(AppContext);
    const [params] = useSearchParams();
    const { trackPageView } = useMatomo();
    const loadCentreDetails = (centre: TCabinet) => {
        setIsDetailsOpen(true);
        setCurrentCentre(centre);

        trackPageView({
            documentTitle: centre.nom,
            href: `/${centre.nom}`,
        });
    };
    const [map, setMap] = useState<any>(null);
    const [typeMap, setTypeMap] = useState<"drom-tom" | "metropole">(
        "metropole"
    );
    const [lightbox, setLightbox] = useState<string | null>(null);

    useEffect(() => {
        (async () => {
            const param = params.get("dept") || "";
            if (param) {
                const tList = await loadListCentre(parseInt(param));
                setListeCentre(tList);
                if (tList.length > 0) {
                    if (map) {
                        map.setView(
                            [
                                tList[0].geolocalisation.latitude,
                                tList[0].geolocalisation.longitude,
                            ],
                            7
                        );
                    }
                }
            } else {
                setListeCentre(await loadListCentre());
                if (map) {
                    map.setView([45.18494442577029, 0.7173670970530021], 6);
                }
            }
        })();

        return () => {
            refMounted.current = false;
        };
    }, [params.get("dept")]);

    useEffect(() => {
        if (map) {
            if (currentCentre) {
                let targetLatLng: any = [
                    currentCentre.geolocalisation.latitude,
                    currentCentre.geolocalisation.longitude,
                ];
                const targetZoom = 20;
                const targetPoint: any = map
                    .project(targetLatLng, targetZoom)
                    .subtract([-1500, 0]);
                targetLatLng = map.unproject(targetPoint, targetZoom);

                map.setView(targetLatLng, targetZoom);
                // map.setView(
                //     [
                //         currentCentre.geolocalisation.latitude,
                //         currentCentre.geolocalisation.longitude,
                //     ],
                //     14
                // );
            } else {
                map.setView([45.18494442577029, 0.7173670970530021], 6);
            }
        }
        return () => {
            refMounted.current = false;
        };
    }, [currentCentre?.id, map]);

    useEffect(() => {
        if (map) {
            if (typeMap === "metropole") {
                map.setView([45.18494442577029, 0.7173670970530021], 6);
            } else {
                map.setView([7.708506, -5.4747209], 3);
            }
        }

        return () => {
            refMounted.current = false;
        };
    }, [typeMap]);

    useEffect(() => {
        (async () => {
            const fetchLightbox = await getLightbox();
            setLightbox(fetchLightbox.contenu);
        })();
    }, []);

    return (
        <>
            <style>{`
                .leaflet-bottom {
                    position: absolute;
                    bottom: 68px;
                }

                .custom-marker-cluster {
                    font-family: var(--font-secondary);
                    font-size: 30px;
                    color: #FFFFFF;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-image: url("/images/marker-cluster.svg");
                    background-size: contain;
                    background-position: center;
                    background-repeat: no-repeat;
                    text-align: center;
                }

                .leaflet-control-attribution {
                    display: flex;
                    gap: 10px;
                }
            `}</style>
            <Box
                position="absolute"
                top={[0, "80px"]}
                left="0"
                right="0"
                bottom="0"
                overflow="hidden"
                zIndex={2}
            >
                <MapContainer
                    center={[45.18494442577029, 0.7173670970530021]}
                    zoom={isMobile ? 5 : 6}
                    scrollWheelZoom={false}
                    style={{ height: "100%" }}
                    zoomControl={false}
                    maxZoom={10}
                    ref={setMap}
                >
                    <VectorTileLayer
                        attribution='<div style="display:flex; gap:10px;"><a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> <a href="https://www.maptiler.com" style="position:relative;z-index:999;"><img src="https://api.maptiler.com/resources/logo.svg" alt="MapTiler logo"></a></div>'
                        styleUrl="https://api.maptiler.com/maps/bright-v2/style.json?key=VxdLGCAegqHSR0pi264b"
                    />

                    <ZoomControl position="bottomleft" />

                    <MarkerClusterGroup
                        chunkedLoading
                        polygonOptions={{ opacity: 0, fillOpacity: 0 }}
                        iconCreateFunction={(cluster: any) => {
                            return L.divIcon({
                                html: `<span>${cluster.getChildCount()}</span>`,
                                className: "custom-marker-cluster",
                                iconSize: L.point(55, 64, true),
                            });
                        }}
                    >
                        {listeCentre.map((item, key) => (
                            <Fragment key={key}>
                                <Marker
                                    position={[
                                        item.geolocalisation.latitude,
                                        item.geolocalisation.longitude,
                                    ]}
                                    icon={L.icon({
                                        iconUrl: "/images/marker.svg",
                                        iconSize: [60, 60],
                                        iconAnchor: [27, 45],
                                    })}
                                    eventHandlers={{
                                        click: () => loadCentreDetails(item),
                                    }}
                                ></Marker>
                            </Fragment>
                        ))}
                    </MarkerClusterGroup>
                </MapContainer>

                {!params.get("dept") && !currentCentre && lightbox && (
                    <Flex
                        direction={"column"}
                        alignItems={"center"}
                        justifyContent={"center"}
                        zIndex={99999}
                        position={"absolute"}
                        top={["70px", "25vh"]}
                        left={[0, "5%"]}
                        right={[0, "unset"]}
                        margin={["auto", "unset"]}
                        width={["100%", "500px"]}
                        px={4}
                        py={3}
                        pb={[3, 10]}
                        _after={{
                            pos: "absolute",
                            top: 0,
                            left: 0,
                            bottom: 0,
                            right: 0,
                            content: "''",
                            backgroundColor: "#f2f8fc",
                            opacity: ".8",
                            zIndex: -1,
                            borderRadius: [0, "15px"],
                        }}
                        gap={3}
                    >
                        <IconButton
                            aria-label="Fermer"
                            variant={"outline"}
                            w={["32px"]}
                            h={["32px"]}
                            minW={"unset"}
                            ml={["auto"]}
                            colorScheme="dark"
                            borderRadius={"100%"}
                            onClick={() => setLightbox(null)}
                        >
                            <CloseIcon />
                        </IconButton>
                        <Text
                            textAlign={"center"}
                            fontWeight={700}
                            fontSize={["16px", "22px"]}
                        >
                            Trouvez un cabinet d'ophtalmologie pour votre
                            injection intravitréenne (IVT) lors de votre
                            déplacement !
                        </Text>
                        <Text
                            fontSize={["14px", "18px"]}
                            fontWeight={700}
                            textAlign={"center"}
                            lineHeight={["20px", "26px"]}
                            maxW={["250px", "unset"]}
                        >
                            {lightbox}
                        </Text>
                        <Image
                            src="/images/arrow-lightbox.svg"
                            transform={[
                                "scaleX(-1) rotate(45deg)",
                                "scaleX(-1) rotate(130deg)",
                            ]}
                            pos={["absolute"]}
                            bottom={["-33px", "35px"]}
                            right={["27px", "-53px"]}
                            w={["unset", "86px"]}
                        />
                    </Flex>
                )}

                <Box
                    role="group"
                    position={"absolute"}
                    bottom={"80px"}
                    left={["unset", "70px"]}
                    right={[0, "unset"]}
                    boxShadow={"0 3px 10px #444444"}
                    width={["150px", "172px"]}
                    height={"116px"}
                    borderRadius={["10px 0 0 10px", "10px"]}
                    bgColor={"#BFDAF2"}
                    bgImage={
                        typeMap === "metropole"
                            ? "/images/fond-drom-com.png"
                            : "/images/fond-metropole.png"
                    }
                    bgPos={"center bottom 30px"}
                    bgRepeat={"no-repeat"}
                    zIndex={99999}
                    overflow={"hidden"}
                    cursor={"pointer"}
                    onClick={() =>
                        setTypeMap((prev) => {
                            if (prev === "drom-tom") {
                                return "metropole";
                            } else {
                                return "drom-tom";
                            }
                        })
                    }
                >
                    <Box
                        pos={"absolute"}
                        left={0}
                        right={0}
                        bottom={0}
                        height={"30px"}
                        bg={"#FFFFFF"}
                        display={["flex"]}
                        alignItems={"center"}
                        justifyContent={"center"}
                        fontWeight={700}
                        fontSize={"13px"}
                        _groupHover={{
                            bgGradient: "linear(to-l, #E4373A, #DF5000)",
                            color: "#FFFFFF",
                        }}
                    >
                        {typeMap === "metropole" ? "Drom/Com" : "Métropole"}
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default MapComponent;
