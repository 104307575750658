import { Box, Flex, Image } from "@chakra-ui/react";
import { Link } from "react-router-dom";

const FooterComponent = () => {
    return (
        <Box
            position="fixed"
            bottom="0"
            left="0"
            right="0"
            px={6}
            zIndex={4}
            _after={{
                content: "''",
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: "#FFFFFF",
                opacity: ".8",
            }}
        >
            <Flex position={"relative"} zIndex={2} height="68px" py={3}>
                <Box display={["none", "flex"]} alignItems={"center"}>
                    <Box
                        fontSize={"16px"}
                        fontWeight={700}
                        color="var(--color-red)"
                    >
                        Légende
                    </Box>
                    <Box
                        width={"32px"}
                        height={37}
                        bgImage="/images/marker.svg"
                        bgSize={"contain"}
                        bgRepeat={"no-repeat"}
                        ml={8}
                    ></Box>
                    <Box
                        fontSize={"13px"}
                        fontWeight={700}
                        ml={3}
                        width={"108px"}
                    >
                        Cabinet d'ophtalmologie
                    </Box>
                    <Flex
                        width={"32px"}
                        height={37}
                        bgImage="/images/marker-cluster.svg"
                        bgSize={"contain"}
                        bgRepeat={"no-repeat"}
                        ml={1}
                        alignItems={"center"}
                        justifyContent={"center"}
                        color={"#FFFFFF"}
                        fontFamily={"var(--font-secondary)"}
                        fontSize={"19px"}
                    >
                        2
                    </Flex>
                    <Box
                        fontSize={"13px"}
                        fontWeight={700}
                        ml={3}
                        width={"183px"}
                    >
                        Plusieurs cabinets d'ophtalmologie dans la zone
                    </Box>
                </Box>
                <Flex
                    fontSize={"12px"}
                    fontWeight={700}
                    alignItems={"center"}
                    justifyContent={"center"}
                    ml={"auto"}
                    mr={3}
                    gap={1}
                    display={["none", "flex"]}
                    textAlign={"center"}
                >
                    <Link
                        to={"https://pro.ivtmobility.com/"}
                        style={{ textDecoration: "underline" }}
                        target="_blank"
                        rel="noopener"
                    >
                        Espace pro
                    </Link>
                    <Box>|</Box>
                    <Link
                        to={"/mentions-legales"}
                        style={{ textDecoration: "underline" }}
                    >
                        Mentions légales
                    </Link>
                    <Box>|</Box>
                    <Link
                        to={"/politique-de-confidentialite"}
                        style={{ textDecoration: "underline" }}
                    >
                        Confidentialité
                    </Link>
                    <Box>|</Box>
                    <Link
                        to={"/conditions-generales-d-utilisation"}
                        style={{ textDecoration: "underline" }}
                    >
                        Conditions d'utilisation
                    </Link>
                </Flex>
                <Flex ml={"auto"} alignItems={"flex-end"} gap={2}>
                    <Box
                        whiteSpace={"nowrap"}
                        mt={2}
                        lineHeight={"12px"}
                        fontSize={"13px"}
                    >
                        Piloté par
                    </Box>
                    <Image
                        src="/images/logo-retine-gallien.svg"
                        alt="Retine Gallien"
                        width={84}
                    />
                    <Box
                        whiteSpace={"nowrap"}
                        mt={2}
                        lineHeight={"12px"}
                        fontSize={"13px"}
                    >
                        avec le soutien de
                    </Box>
                    <Image
                        src="/images/logo-bayer.svg"
                        alt="Bayer"
                        width={37}
                    />
                </Flex>
            </Flex>
        </Box>
    );
};

export default FooterComponent;
